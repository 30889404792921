import React, { useState, useEffect } from "react";
import { Dropdown, Alert } from "react-bootstrap";
import {
  getData,
  markAsViewed,
  markAsReaded,
} from "~/App/services/NotificationService";

import If from "~App/components/If";
import NotificationItem from "~/App/components/NotificationHeader/NotificationItem";
import NotificationTitle from "~/App/components/NotificationHeader/NotificationTitle";
import { useNotifications } from "~/App/contexts/notification";
import { useAuth } from "~/App/contexts/auth";
import api from "~/App/services/api";

const NotificationHeader = (props) => {
  const { list, setList, badge, setBadge } = useNotifications();
  const { token } = useAuth();
  // const [list, setList] = useState([]);
  // const [badge, setBadge] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onClear = () => {
    markAsReaded(0);
    setBadge(0);
    setList([]);
  };

  const handleViewAll = () => {};
  const onNotificationContainerOpened = async (isOpen, event, metadata) => {
    if (!isOpen) {
      markAsViewed(0);
      setBadge(0);
      setList([
        ...list.map((item) => {
          item.viewed = true;
          return item;
        }),
      ]);
    }
  };

  useEffect(() => {
    async function load() {
      try {
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        const data = await getData();
        const notViewed = data.filter((item) => !item.viewed);
        setBadge(notViewed.length);
        setList(data);
      } catch (error) {
        console.error("Error:", error);
        setMessages(error);
      }
    }
    load();
  }, []);

  return (
    <li>
      {/* <If condition={messages}>
        {messages.map((item) => (
          <Alert key={item.name} variant={"danger"}>
            {item.message}
          </Alert>
        ))}
      </If> */}
      <Dropdown
        alignRight={!props.rtlLayout}
        onToggle={onNotificationContainerOpened}
      >
        <Dropdown.Toggle variant={"link"} id="dropdown-basic">
          <i className="icon feather icon-bell bell" style={{ fontSize: 20 }} />
          <If condition={badge}>
            <span className={"badge theme-bg3"}>{badge}</span>
          </If>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="notification">
          <div className="noti-head">
            <h6 className="d-inline-block m-b-0">Notificações</h6>
            <div className="float-right">
              <a onClick={onClear} style={{ cursor: "pointer" }}>
                Limpar
              </a>
            </div>
          </div>
          <ul className="noti-body">
            <If condition={list.length <= 0}>
              <NotificationTitle title="Não há notificações!" align="center" />
            </If>
            {list
              // .filter((notification) => !notification.viewed)
              .map((notification) => {
                return (
                  <NotificationItem
                    notification={notification}
                    key={notification.id}
                  />
                );
              })}
          </ul>
          {/* <div className="noti-footer">
            <a style={{ cursor: "pointer" }} onClick={handleViewAll}>
              Ver Todas
            </a>
          </div> */}
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
};
export default NotificationHeader;
