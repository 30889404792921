import React from "react";

// import { Container } from './styles';
import Moment from "react-moment";
import "moment/locale/pt-br";
import { useHistory } from "react-router";
import { NavLink, Redirect } from "react-router-dom";
function NotificationItem({ notification }) {
  const history = useHistory();
  const onClick = () => {
    history.push(notification.url);
  };
  return (
    <li
      style={{ cursor: notification.url && "pointer" }}
      onClick={onClick}
      url={notification.url}
      key={notification.id}
      className={`notification ${!notification.viewed && "new"}`}
    >
      <a
        href={notification.url}
        style={{ padding: "initial", fontSize: "initial" }}
      >
        <div className="media">
          {/* <Link to={notification.url}> */}
          <div className={`icon_container ${notification.color}`}>
            <i className={`feather ${notification.icon}`} />
          </div>
          <div className="media-body">
            <p>
              <strong>{notification.title}</strong>
              <span className="n-time text-muted">
                <i className="icon feather icon-clock m-r-10" />
                <Moment fromNow ago locale="pt-br">
                  {notification.dateTime}
                </Moment>
              </span>
            </p>
            <p>{notification.description}</p>
          </div>
          {/* </Link> */}
        </div>
      </a>
    </li>
  );
}

export default NotificationItem;
