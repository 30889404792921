import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import ChatList from "./ChatList";
import UserSettings from "~/App/layout/AdminLayout/NavBar/NavRight/UserSettings";
import Aux from "~/hoc/_Aux";


import NotificationHeader from "~/App/components/NotificationHeader/index";
const defaultList = [
  {
    title: "Título",
    description: "Notification Description",
    dateTime: "27/01/2020 02:02",
    icon: "icon-home",
    viewed: false,
  },
  {
    title: "Título",
    description: "Notification Description",
    dateTime: "27/01/2020 02:02",
    icon: "icon-home",
    viewed: true,
  },
];

const NavRight = (props) => {
  const [listOpened, setListOpened] = useState(false);
  const [list, setList] = useState(defaultList);
  return (
    <Aux>
      <ul className="navbar-nav ml-auto">
        <NotificationHeader />
        <UserSettings />
      </ul>
    </Aux>
  );
};

export default NavRight;
