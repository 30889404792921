import React from "react";

const Users = React.lazy(() => import("~/App/views/Users"));
const CreateUser = React.lazy(() => import("~/App/views/Users/create"));
const EditUser = React.lazy(() => import("~/App/views/Users/edit"));
const Profile = React.lazy(() => import("~/App/views/Profile"));

const routes = [
  {
    path: "/users",
    exact: true,
    name: "Usuários",
    component: Users,
    auth: true,
  },
  {
    path: "/users/create",
    exact: true,
    name: "Criar Usuário",
    component: CreateUser,
    auth: true,
  },
  {
    path: "/users/:id/edit",
    exact: true,
    name: "Editar Usuário",
    component: EditUser,
    auth: true,
  },
  {
    path: "/users/profile",
    exact: true,
    name: "Peril do Usuário",
    component: Profile,
    auth: true,
  },
];

export default routes;
