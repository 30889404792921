// import api, {setAuthToken} from '@services/api';
// import { authenticate } from "@services/auth";
import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getData,
  markAsViewed,
  markAsReaded,
} from "~/App/services/NotificationService";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [list, setList] = useState([]);
  const [badge, setBadge] = useState(0);

  const onClear = () => {
    markAsReaded(0);
    setBadge(0);
    setList([]);
  };
  const onNotificationContainerOpened = async (isOpen, event, metadata) => {
    if (!isOpen) {
      markAsViewed(0);
      setBadge(0);
      setList([
        ...list.map((item) => {
          item.viewed = true;
          return item;
        }),
      ]);
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        list,
        setList,
        badge,
        setBadge,
        onClear,
        onNotificationContainerOpened,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotifications() {
  const context = useContext(NotificationContext);
  const {
    list,
    setList,
    badge,
    setBadge,
    onClear,
    onNotificationContainerOpened,
  } = context;
  return {
    list,
    setList,
    badge,
    setBadge,
    onClear,
    onNotificationContainerOpened,
  };
}
