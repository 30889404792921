import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Login = React.lazy(() => import("~/App/views/Login"));
const ChangePassword = React.lazy(() => import("~/App/views/ChangePassword"));
const RecoveryPassword = React.lazy(() =>
  import("~/App/views/RecoveryPassword")
);

const authRoutes = [
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
  },
  {
    path: "/recovery",
    exact: true,
    name: "Recuperar Senha",
    component: RecoveryPassword,
  },
  {
    path: "/change/:token?",
    exact: true,
    name: "Trocar Senha",
    component: ChangePassword,
  },
];

export default authRoutes;
