// import api, {setAuthToken} from '@services/api';
// import { authenticate } from "@services/auth";
import React, { createContext, useState, useContext, useEffect } from "react";
import SessionStorageService from "~/App/services/SessionStorageService";
import api from "~/App/services/api";
const AuthContext = createContext();

const USER_TOKEN = "USER_TOKEN";
const USER_SIGNED = "USER_SIGNED";
const USER_OBJECT = "USER_OBJECT";

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [userImage, setUserImage] = useState();
  const [token, setToken] = useState();
  const [signed, setSigned] = useState();
  const [user, setUser] = useState();

  const [loading, setLoading] = useState(true);

  function changeUser(newUser) {
    setUserId(newUser.id);
    setUserName(newUser.name);
    setUserImage(newUser.image);
    setUser(newUser);
    SessionStorageService.set(USER_OBJECT, newUser);
  }

  function signIn(user, token) {
    setUserId(user.id);
    setUserName(user.name);
    setUserImage(user.image);
    setSigned(true);
    setUser(user);
    setToken(token);
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    SessionStorageService.set(USER_TOKEN, token);
    SessionStorageService.set(USER_SIGNED, true);
    SessionStorageService.set(USER_OBJECT, user);
  }

  function signOut() {
    setSigned(false);
    setUser({});
    setToken("");
    setUserId(0);
    setUserName("");
    setUserImage("");

    SessionStorageService.remove(USER_TOKEN);
    SessionStorageService.remove(USER_SIGNED);
    SessionStorageService.remove(USER_OBJECT);
  }

  useEffect(() => {
    const sessionToken = SessionStorageService.get(USER_TOKEN);
    const sessionSigned = SessionStorageService.get(USER_SIGNED);
    const sessionUser = SessionStorageService.get(USER_OBJECT);

    if (!token && sessionToken) {
      api.defaults.headers.common.Authorization = `Bearer ${sessionToken}`;
      setToken(sessionToken);
    }
    if (!signed && sessionSigned) setSigned(sessionSigned);
    if (!user && sessionUser) {
      setUser(sessionUser);
      setUserId(sessionUser.id);
      setUserName(sessionUser.name);
      setUserImage(sessionUser.image);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        signed,
        user,
        userId,
        userName,
        userImage,
        loading,
        setSigned,
        changeUser,
        signIn,
        signOut,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  const {
    token,
    signed,
    user,
    userId,
    userName,
    userImage,
    loading,
    signIn,
    signOut,
    changeUser,
    setSigned,
    setLoading,
  } = context;
  return {
    token,
    signed,
    user,
    userId,
    userName,
    userImage,
    loading,
    signIn,
    signOut,
    changeUser,
    setSigned,
    setLoading,
  };
}
