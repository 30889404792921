import React from "react";

// import { Container } from './styles';

function NotificationTitle({ title, align }) {
  return (
    <li
      className="n-title"
      style={{ display: "flex", justifyContent: align || "flex-start" }}
    >
      <p className="m-b-0" style={{ marginBottom: "revert" }}>
        {title}
      </p>
    </li>
  );
}

export default NotificationTitle;
