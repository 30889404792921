import React from "react";

const TypeProjectList = React.lazy(() => import("~/App/views/TypeProject"));
const TypeProjectCreate = React.lazy(() =>
  import("~/App/views/TypeProject/create")
);
const TypeProjectEdit = React.lazy(() =>
  import("~/App/views/TypeProject/edit")
);

export default [
  {
    path: "/type-project",
    exact: true,
    name: "Tipo Projeto",
    component: TypeProjectList,
  },
  {
    path: "/type-project/create",
    exact: true,
    name: "Detalhe do Tipo Projeto",
    component: TypeProjectCreate,
  },
  {
    path: "/type-project/:id/edit",
    exact: true,
    name: "Editar Tipo Projeto",
    component: TypeProjectEdit,
  },
];
