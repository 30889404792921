import React from "react";

const ClosePopup = React.lazy(() => import("~/App/views/ClosePopup"));
const Configuration = React.lazy(() => import("~/App/views/Configuration"));

export default [
  {
    path: "/configuration",
    exact: true,
    name: "Configurações",
    component: Configuration,
  },
  {
    path: "/close-popup",
    exact: true,
    name: "ClosePopup",
    component: ClosePopup,
  },
];
