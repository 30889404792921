import React from "react";
import $ from "jquery";

import UserRoutes from "~/routes/user";
import ClientRoutes from "~/routes/client";
import ProjectRoutes from "~/routes/project";
import CalendarRoutes from "~/routes/calendar";
import ConfigurationRoutes from "~/routes/configuration";
import TypeProjectRoutes from "~/routes/typeProject";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const routes = [
  ...ClientRoutes,
  ...UserRoutes,
  ...ProjectRoutes,
  ...CalendarRoutes,
  ...TypeProjectRoutes,
  ...ConfigurationRoutes,
];

export default routes;
