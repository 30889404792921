import React from "react";
import { Dropdown } from "react-bootstrap";
import { useAuth } from "~/App/contexts/auth";
import DEMO, { LINKS } from "~/store/constant";
import Avatar from "react-avatar";
import { useHistory } from "react-router";

export default function UserSettings(props) {
  const { userName, userImage, signOut } = useAuth();
  const history = useHistory();
  const handleLogout = () => {
    signOut();
    history.push("/");
  };
  return (
    <li>
      <Dropdown alignRight={!props.rtlLayout} className="drp-user">
        <Dropdown.Toggle variant={"link"} id="dropdown-basic">
          <i className="icon feather icon-settings" style={{ fontSize: 20 }} />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="profile-notification">
          <div className="pro-head">
            <Avatar
              name={userName}
              src={userImage}
              round={true}
              size={35}
              style={{ marginRight: 10 }}
            />
            <span>{userName}</span>
            <a onClick={handleLogout} className="dud-logout" title="Logout">
              <i className="feather icon-log-out" />
            </a>
          </div>
          <ul className="pro-body">
            <li>
              <a href={LINKS.profile} className="dropdown-item">
                <i className="feather icon-user" /> Perfil do Usuário
              </a>
            </li>
            {/* <li>
              <a href={DEMO.BLANK_LINK} className="dropdown-item">
                <i className="feather icon-settings" /> Configurações
              </a>
            </li> */}
            {/* <li>
              <a href={DEMO.BLANK_LINK} className="dropdown-item">
                <i className="feather icon-mail" /> My Messages
              </a>
            </li> */}
            {/* <li>
              <a href={DEMO.BLANK_LINK} className="dropdown-item">
                <i className="feather icon-lock" /> Lock Screen
              </a>
            </li> */}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
}
