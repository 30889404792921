import React from "react";

const Clients = React.lazy(() => import("~/App/views/Clients"));
const CreateClient = React.lazy(() => import("~/App/views/Clients/create"));
const EditClient = React.lazy(() => import("~/App/views/Clients/edit"));

export default [
  { path: "/clients", exact: true, name: "Clientes", component: Clients },
  {
    path: "/clients/create",
    exact: true,
    name: "Criar Cliente",
    component: CreateClient,
    auth: true,
  },
  {
    path: "/clients/:id/edit",
    exact: true,
    name: "Editar Cliente",
    component: EditClient,
  },
];
