import React from "react";
import api from "~/App/services/api";

export const columns = [
  { field: "id", description: "#", type: "number", showInForm: false },
  { field: "name", description: "Nome", type: "text" },
  { field: "email", description: "Email", type: "email" },
  { field: "status", description: "Ativo", type: "checkbox" },
];

export const getData = async (page = 1, pageSize = 10, filters = []) => {
  try {
    const response = await api.get("/notification?readed=false");
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
  //   const response = await api.get("/notification");
  //   return response.data;
};

export const create = async (data) => {
  try {
    const response = await api.post("/notification", data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};
export const markAsReaded = async (id) => {
  // return api.put(`/notification/readed/${id}`);

  try {
    const response = await api.put(`/notification/readed/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};
export const markAsViewed = async (id) => {
  // return api.put(`/notification/viewed/${id}`);
  try {
    const response = await api.put(`/notification/viewed/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};

export const trash = async (id) => {
  // return api.delete(`/notification/${id}`);
  try {
    const response = await api.delete(`/notification/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};
