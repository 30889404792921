import React from "react";

import { getData } from "~/App/services/TypeProject";

// const returnMenus = async () => {
//   const response = await getData();

//   const menuTypeProjetct = response.map((item) => {
//     return {
//       id: item.id,
//       title: item.name,
//       type: "item",
//       url: `type-project/${item.id}/edit`,
//       icon: "feather icon-tag",
//     };
//   });

const menus = {
  items: [
    {
      id: "navigation",
      title: "Projetos",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "home",
          title: "Home",
          type: "item",
          url: "/",
          icon: "feather icon-home",
        },
        {
          id: "clientes",
          title: "Clientes",
          type: "item",
          url: "/clients",
          icon: "feather icon-users",
        },

        {
          id: "type-project",
          title: "Tipo Projeto",
          type: "item",
          url: "/type-project",
          icon: "feather icon-tag",
        },
        {
          id: "project",
          title: "Projetos",
          type: "item",
          url: "/project",
          icon: "feather icon-server",
        },
      ],
    },
    {
      id: "navigation",
      title: "Configurações",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "usuarios",
          title: "Usuários",
          type: "item",
          url: "/users",
          icon: "feather icon-lock",
        },
        {
          id: "google",
          title: "Google",
          type: "item",
          url: "/configuration",
          icon: "feather icon-settings",
        },
      ],
    },
  ],
};

export default menus;
