import React from "react";

const ProjectDetail = React.lazy(() => import("~/App/views/Project/Detail"));
const ProjectCreate = React.lazy(() => import("~/App/views/Project/Create"));
const Project = React.lazy(() => import("~/App/views/Project"));

export default [
  {
    path: "/project",
    exact: true,
    name: "Projetos",
    component: Project,
    auth: true,
  },
  {
    path: "/project/:id/detail",
    // path: "/project/detail",
    exact: true,
    name: "Detalhe do Projeto",
    component: ProjectDetail,
  },
  {
    path: "/project/create",
    // path: "/project/detail",
    exact: true,
    name: "Criar Projeto",
    component: ProjectCreate,
  },
];
