import React, { useEffect, Suspense } from "react";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "~/App/contexts/auth";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import authRoutes from "~/routes/auth";
import AdminLayout from "./layout/AdminLayout";
import Socket from "~/App/components/Socket";
import { NotificationProvider } from "~/App/contexts/notification";
import "~/App/util/prototypes";
const App = () => {
  const { signed, token } = useAuth();

  const authRoute = authRoutes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  return (
    <Aux>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            {signed && token ? (
              <NotificationProvider>
                <Route path="/" component={AdminLayout} />
                <Socket />
              </NotificationProvider>
            ) : (
              authRoute
            )}
            <Redirect exact from="/" to={signed ? "/" : "/login"} />
            {/* <Redirect exact from="*" to={signed ? "/" : "/login"} /> */}
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
};

export default App;
