import React from "react";
import useSound from "use-sound";

import config from "~/config";
import socketIOClient from "socket.io-client";
import { useAuth } from "~/App/contexts/auth";
import { useNotifications } from "~/App/contexts/notification";
import justMaybe from "~/assets/audio/just-maybe.mp3";

export default function Socket() {
  const { token } = useAuth();
  const [play] = useSound(justMaybe);
  const { list, setList, badge, setBadge } = useNotifications();

  if (token) {
    const socket = socketIOClient(config.backendURL, { query: { token } });

    socket.on("newNotification", (receivedInfo) => {
      play();
      setList([receivedInfo, ...list]);
      setBadge(Number(badge) + 1);
    });
  }

  return <> </>;
}
