import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./styles";
import App from "~/App/index";
import reducer from "~/store/reducer";
import config from "~/config";
import { AuthProvider } from "~/App/contexts/auth";

const store = createStore(reducer);

const app = (
  <Provider store={store}>
    <AuthProvider>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
