import React from "react";

const Calendar = React.lazy(() => import("~/App/views/Calendar"));

export default [
  {
    path: "/",
    exact: true,
    name: "Calendárior",
    component: Calendar,
  },
];
