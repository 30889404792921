import React from "react";
import api from "~/App/services/api";
import { Button, Badge } from "react-bootstrap";
import { pickTextColorBasedOnBgHex } from "~/App/util";

export const columns = [
  {
    field: "id",
    description: "#",
    type: "number",
    showInForm: false,
    showInGrid: false,
    gridOptions: {
      containerStyle: { maxWidth: 0 },
      style: { maxWidth: 50 },
    },
  },

  {
    field: "name",
    description: "Nome",
    type: "text",
    formOptions: { required: true },
    gridRender: (item, row) => {
      return (
        <div>
          {item}
          <Badge
            style={{
              marginLeft: 10,
              marginRight: 10,
              backgroundColor: row.color,
              padding: 8,
              color: pickTextColorBasedOnBgHex(row.color),
            }}
          >
            {row.abbreviation}
          </Badge>
        </div>
      );
    },
  },
  { field: "country", description: "País", type: "text", showInForm: false },
  {
    field: "type",
    description: "Tipo do Visto",
    type: "text",
    showInForm: false,
  },
];

export const getData = async (page = 1, pageSize = 10, filters) => {
  const response = await api.get("/type-project");
  return response.data;
};

export const getOneById = async (id) => {
  try {
    const response = await api.get(`/type-project/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};

export const create = async (data) => {
  try {
    const response = await api.post("/type-project", data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};

export const edit = async (data) => {
  try {
    const response = await api.put("/type-project", data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};

export const trash = async (id) => {
  try {
    await api.delete(`/type-project/${id}`);
    return true;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.data) {
      throw error.data;
    } else {
      throw [{ message: `Ocorreu um erro: ${error}` }];
    }
  }
};
