import axios from "axios";
import config from "~/config";
const api = axios.create({
  baseURL: config.backendURL,
  // baseURL: "http://localhost:3333/",
  headers: {
    "Content-Type": "application/json",
    // 	Authorization:
    // 		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjg3LCJlbWFpbCI6ImpvbmF0YWhvZm1hbm5AZ21haWwuY29tIiwiaWF0IjoxNjAzOTkxMTU4fQ.c9BvlVpJriNjypqNh9EtP00lXXkhCFVFCWwqbVLqwpE',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common.Authorization = "Bearer " + token;
  } else {
    delete api.defaults.headers.common.Authorization;
  }
};

// api.interceptors.request.use(
// 	async requestConfig => {
// 		requestConfig.headers.common.Authorization = `Bearer 778466366de002a80a9035ed3500d1a1`;
// 		return requestConfig;
// 	},
// 	response => Promise.reject(response),
// );

export default api;
